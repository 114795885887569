/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import GreyCheck from 'img/snel_grey_check.svg'

// Components
import { Paragraph } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

const SellingPointsWrapper = styled.div``

const USP = styled.div``

const SellingPoints = ({ proto, data, className }) => {
  return (
    <SellingPointsWrapper className={`${className ? `${className}` : ``}`}>
      {proto ? (
        <>
          {data.filter((o) => o.name === "li").map((usp, index) => (
            <USP className="d-flex pb-1" key={index}>
              <img className="pr-2" src={GreyCheck} alt="" />
              <Paragraph>{usp.children[0].data}</Paragraph>
            </USP>
          ))}
        </>
      ) : (
        <>
          {data.acf.usps.map((usp, index) => (
            <USP className="d-flex pb-1" key={index}>
              <img className="pr-2" src={usp.icon.localFile.publicURL} alt="" />
              <Paragraph>{usp.text}</Paragraph>
            </USP>
          ))}
        </>
      )}
      
    </SellingPointsWrapper>
  )
}

export default SellingPoints