import React from 'react'

// Images
import RedChevronRight from 'img/snel_red_chevron_right.svg'

// Componenten
import { FirstHeader, SecondHeader, Paragraph } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

const TitleWrapper = styled.div``

const ContentContainer = styled.div`
  @media (max-width: 991px) {
    width: 100%;
  }
`

const Title = ({ mainheader, proto, luxe, data, className }) => {
  return (
    <TitleWrapper className={`${className ? `${className}` : ``}`}>
      {proto ? (
        <>
          {luxe ? (
            <div className="d-flex flex-wrap align-items-center">
              <ContentContainer className="d-flex align-items-center">
                <img className="pl-1 pr-3" src={RedChevronRight} alt="" />
                {mainheader ? (
                  <FirstHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.title}</FirstHeader>
                ) : (
                  <SecondHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.title}</SecondHeader>
                )}
              </ContentContainer>
              <Paragraph className="font-size-m color-text-contrast">{data.sub_text}</Paragraph>
            </div>       
          ) : (
            <>
              {mainheader ? (
                <FirstHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.title}</FirstHeader>
              ) : (
                <SecondHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.title}</SecondHeader>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {luxe ? (
            <div className="d-flex flex-wrap align-items-center">
              <ContentContainer className="d-flex align-items-center">
                <img className="pl-1 pr-3" src={data.acf.title_group.icon.localFile.publicURL} alt="" />
                {mainheader ? (
                  <FirstHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.acf.title}</FirstHeader>
                ) : (
                  <SecondHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.acf.title}</SecondHeader>
                )}
              </ContentContainer>
              <Paragraph className="font-size-m color-text-secondary">{data.acf.title_group.sub_text}</Paragraph>
            </div>       
          ) : (
            <>
              {mainheader ? (
                <FirstHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.acf.title}</FirstHeader>
              ) : (
                <SecondHeader className="m-0 font-size-18 pr-3 font-weight-xl text-uppercase">{data.acf.title}</SecondHeader>
              )}
            </>
          )}
        </>
      )}
      
    </TitleWrapper>
  )
}

export default Title