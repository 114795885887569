/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Images
import greenCheck from 'img/green_check.svg'
import whitePhone from 'img/white_phone.svg'
import whatsappWhite from 'img/whatsapp_white.svg'

// Components
import SEO from 'components/SEO'
import ButtonDefault from 'components/Elements/ButtonDefault'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import WaardeContent from 'components/Diensten/WaardeContent'
import ImageBanner from 'components/Common/ImageBanner'
import GravityForm from 'components/GravityForm/GravityForm'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const FormContainer = styled.div`
  ${(props) =>
    props.completed
      ? css`
          background-color: ${props.theme.color.secondary};
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

          @media screen and (max-width: 991px) {
            box-shadow: none;
          }
        `
      : css`
          background-color: ${props.theme.color.secondary};
        `}

  position: relative;
  top: -235px;

  @media screen and (max-width: 991px) {
    top: 0;
  }
`

const StyledImageBanner = styled(ImageBanner)`
  & .gatsby-image-wrapper {
    max-height: 500px;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;

    @media (max-width: 991px) {
      height: 300px;
    }
  }
`

const BlueBar = styled.div`
  background-color: ${(props) => props.theme.color.main};

  h2 {
    padding: 40px 0 40px 0;

    @media screen and (max-width: 991px) {
      text-align: center;
      padding: 20px 0 20px 0;
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  min-width: 238px;

  & p {
    color: #fff;
  }

  ${(props) =>
    props.green &&
    css`
      background-color: #4da02e;
      border-color: #4da02e;
    `}
`

const WaardebepalingTemplate = ({ data: { page }, pageContext }) => {
  const [formComplete, setFormComplete] = useState(false)

  return (
    <Layout>
      {/* {() => ( */}
        <>
          <SEO seo={page.seo} />
          <div>
            <CustomBreadCrumb
              data={page}
              className="py-2"
              pageContext={pageContext}
            />
            {page.templateWaardebepaling.headerBanner.imageMobile && (
              <Plaatjie
                loading="eager"
                image={page.templateWaardebepaling.headerBanner.imageMobile}
                className="d-md-none"
                alt=""
              />
            )}
            <StyledImageBanner
              image={page.templateWaardebepaling.headerBanner.image}
              className={`pb-0 ${
                page.templateWaardebepaling.headerBanner.imageMobile
                  ? 'd-none d-md-block'
                  : 'd-block'
              }`}
            />
            <BlueBar>
              <div className="container">
                <h2 className="color-text-light text-uppercase">
                  {formComplete
                    ? `Bedankt voor je aanvraag`
                    : `Benieuwd wat jouw woning waard is?`}
                </h2>
              </div>
            </BlueBar>
            <div className="container">
              <div
                className={!formComplete ? `row py-lg-5 py-4` : `row py-lg-5`}
              >
                <div
                  className={
                    !formComplete
                      ? `col-12 col-lg-7 pl-lg-3 pr-lg-5`
                      : 'd-lg-block d-none col-12 col-lg-7 pl-lg-3 pr-lg-5'
                  }
                >
                  <WaardeContent
                    data={page.templateWaardebepaling.extraContent}
                  />
                  <div className="row pt-3">
                    <div className="col-lg-5 d-lg-block d-none">
                      <Plaatjie
                        loading="eager"
                        image={page.templateWaardebepaling.extraContent.image}
                        alt=""
                      />
                    </div>
                    <div className="col-lg-7">
                      <div className="pb-lg-5">
                        {page.templateWaardebepaling.extraContent.points.map(
                          (p) => (
                            <div className="d-flex align-items-center pl-lg-0 pl-4">
                              <img className="mr-2" src={greenCheck} alt="" />
                              <p className="mb-1">{p.text}</p>
                            </div>
                          )
                        )}
                      </div>
                      <div className="pb-3 d-none d-lg-flex justify-content-center justify-content-md-start">
                        <StyledButtonDefault
                          isAnchor
                          to={`tel:${page.templateWaardebepaling.extraContent.phonenumber}`}
                        >
                          <div className="d-flex align-items-center px-2">
                            <img className="mr-2" src={whitePhone} alt="" />
                            <p className="mb-0 font-size-xm">
                              Bel voor een afspraak
                            </p>
                          </div>
                        </StyledButtonDefault>
                      </div>
                      <div className="d-none d-lg-flex justify-content-center justify-content-md-start">
                        <StyledButtonDefault
                          green
                          isAnchor
                          to={`whatsapp:${page.templateWaardebepaling.extraContent.whatsapp}`}
                        >
                          <div className="d-flex align-items-center px-2">
                            <img className="mr-2" src={whatsappWhite} alt="" />
                            <p className="mb-0 font-size-xm">
                              App voor een afspraak
                            </p>
                          </div>
                        </StyledButtonDefault>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    !formComplete
                      ? `col-12 px-0 col-lg-5 pt-5 pt-lg-0 pr-lg-3`
                      : `col-12 px-0 col-lg-5 pt-lg-0 pr-lg-3`
                  }
                >
                  <FormContainer
                    completed={formComplete}
                    className="p-sm-5 p-3"
                  >
                    {!formComplete && (
                      <h2 className="text-uppercase font-weight-xl text-center pb-4">
                        Gratis waardebepaling aanvragen
                      </h2>
                    )}
                    <GravityForm
                      onComplete={setFormComplete}
                      waarde
                      redButton
                      id={6}
                    />
                  </FormContainer>
                </div>
              </div>
            </div>
          </div>
        </>
      {/* )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      templateWaardebepaling {
        headerBanner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
          imageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 850)
              }
            }
          }
        }
        extraContent {
          content
          points {
            text
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 900)
              }
            }
          }
          phonenumber
          whatsapp
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default WaardebepalingTemplate
