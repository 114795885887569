import React from 'react'

// Components
import CustomLink from 'components/CustomLink'
import { Paragraph } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

const CustomButtonWrapper = styled.div``

const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    transition: 0.25s;
    min-width: 264px;
    min-height: 50px;
    border-radius: 59px;

    :hover {
      background-color: #242944;
    }
  }
`

const CustomButton = ({ external, newPage, proto, number, data, className }) => {
  return (
    <CustomButtonWrapper className={`d-flex ${className ? `${className}` : ``}`}>
      {proto ? (
        <StyledCustomLink external={external} newPage={newPage} to={data.url}>
          <Paragraph className="float-left py-2 px-4 d-flex justify-content-center align-items-center color-background-striking color-text-light text-uppercase font-weight-xl font-size-m">{data.title.data}</Paragraph>
        </StyledCustomLink>
      ) : (
        <StyledCustomLink external={external} newPage={newPage} to={data.acf[`link_${number}`].url}>
          <Paragraph className="float-left py-2 px-4 d-flex justify-content-center align-items-center color-background-striking color-text-light text-uppercase font-weight-xl font-size-m">{data.acf[`link_${number}`].title}</Paragraph>
        </StyledCustomLink>
      )}

    </CustomButtonWrapper>
  )
}

export default CustomButton