import React from 'react'
import backgroundImg from 'gatsby-background-image'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const ImageBannerWrapper = styled.div``

const StyledImg = styled(Plaatjie)`
  ${props => props.big ? css`
    height: 500px;

    @media (max-width: 991px) {
      height: 350px;
    }

  ` : css`
    height: 400px;

    @media (max-width: 991px) {
      height: 300px;
    }

  `}
`

const ImageBanner = ({ image, data, className }) => {

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(87deg, rgba(39,45,70,0.2) 0%, rgba(39,45,70,0.2) 100%)`
    ].reverse();
    return gradient;
  }


  return (
    <ImageBannerWrapper className={`${className ? `${className}` : ``}`}>
      {image ? (
        <StyledImg big loading="eager" image={image} alt="" />
      ) : (
        <StyledImg loading="eager" image={addGradient(data.recapPost.image)} alt="" />
      )}
    </ImageBannerWrapper>
  )
}

export default ImageBanner