import React from 'react'

// Components
import { Paragraph, SecondHeader } from 'components/StyledComponents'
import GravityForm from 'components/GravityForm'

// Images
import redCross from 'img/snel_red_cross.svg'

// Third Party
import styled from 'styled-components'
import Modal from 'react-modal'

const CloseButtonContainer = styled.div`
  cursor: pointer;
  top: 10px;
  right: 10px;
  z-index: 100;
`

const StyledModal = styled(Modal)`
  position: relative;
`

const CustomStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '60'
  }
}

const ModalContent = styled.div``

const InnerModal = styled.div`
  max-height: 825px;
  width: 675px;
  border-radius: 5px;

  ${ModalContent} {
    max-height: 815px;
    width: 665px;
  }

  @media (max-width: 767px) {
    height: 100%;
    width: 425px;

    ${ModalContent} {
      height: 100%;
      width: 375px;
    }
  }

  @media (max-width: 575px) {
    height: 100%;
    width: 350px;

    ${ModalContent} {
      height: 100%;
      width: 300px;
    }
  }

  @media (max-width: 350px) {
    height: 100%;
    width: 300px;

    ${ModalContent} {
      height: 100%;
      width: 250px;
    }
  }
`

const StyledImg = styled.img`
  height: 30px;
  width: 30px;
  transition: 0.25s;

  :hover {
    transform: scale(1.05);
  }
`

const ContactModal = ({ service, currentObject, type, title, woningen, id, modalIsOpen, setModalOpen, className }) => (
  <StyledModal
    isOpen={modalIsOpen}
    onRequestClose={() => setModalOpen(false)}
    style={CustomStyles}
    contentLabel="contact-modal"
  >
    <InnerModal className="color-background-secondary d-flex justify-content-center align-items-center">
      <ModalContent className="color-background-secondary-darker d-flex flex-wrap justify-content-center align-items-center">
        <CloseButtonContainer className="position-absolute" onClick={() => setModalOpen(false)}>
          <StyledImg src={redCross} alt="" />
        </CloseButtonContainer>
        <div className="py-5 w-100">
          <SecondHeader className="w-100 d-flex justify-content-center font-weight-xl text-uppercase pt-2">{title}</SecondHeader>
          <Paragraph className="w-100 d-flex justify-content-center font-weight-xl text-uppercase">{type}</Paragraph>
        </div>
        <div className={`d-flex color-background-secondary pb-2 pb-lg-5 px-2 px-lg-5 flex-wrap justify-content-lg-start justify-content-center ${className ? `${className}` : ``}`}>
          <GravityForm service={service} currentObject={currentObject} woningen={woningen} redButton id={id} />
        </div>
      </ModalContent>
    </InnerModal>
  </StyledModal>
)

export default ContactModal