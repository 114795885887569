import React, { useState } from 'react'

// Images
import whitePhone from 'img/white_phone.svg'
import whatsappWhite from 'img/whatsapp_white.svg'

// Components
import CustomButton from 'components/Diensten/CustomButton'
import Title from 'components/Diensten/Title'
import SellingPoints from 'components/Diensten/SellingPoints'
import { Paragraph } from 'components/StyledComponents'
import ContactModal from 'components/Common/ContactModal'
import CustomLink from 'components/CustomLink'
import ButtonDefault from 'components/Elements/ButtonDefault'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const ContentWrapper = styled.div``

const InnerContentContainer = styled.div`
  p {
    line-height: 1.71;
    letter-spacing: -0.19px;
    font-size: ${(props) => props.theme.font.size.sm};

    @media screen and (max-width: 991px) {
      text-align: justify;
    }
  }

  a {
    p {
      font-size: ${(props) => props.theme.font.size.ms};
    }
  }

  h1 {
    font-size: 24px;
    padding-bottom: 10px;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.ms};
  }

  strong {
    text-transform: uppercase;
  }
`

const CustomButtonWrapper = styled.div``

const StyledCustomLink = styled.div`
  cursor: pointer;

  ${Paragraph} {
    transition: 0.25s;
    min-width: 264px;
    min-height: 50px;

    :hover {
      transform: scale(1.05);
    }
  }
`

const LinkCustomLink = styled(CustomLink)`
  display: inline-block;

  ${Paragraph} {
    font-size: 14px !important;
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  min-width: 238px;

  ${(props) =>
    props.green &&
    css`
      background-color: #4da02e;
      border-color: #4da02e;
    `}
`

const WaardeContent = ({ data, className }) => {
  const [modalIsOpen, setModalOpen] = useState(false)

  return (
    <ContentWrapper className={`${className ? `${className}` : ``}`}>
      <InnerContentContainer>
        {parse(data.content, {
          replace: (domNode) => {
            if (domNode.name === 'h3') {
              if (domNode.children.find((o) => o.name === 'a')) {
                if (
                  domNode.children
                    .find((o) => o.name === 'a')
                    .attribs.href.indexOf('http://') === 0 ||
                  domNode.children
                    .find((o) => o.name === 'a')
                    .attribs.href.indexOf('https://') === 0
                ) {
                  return (
                    <CustomButton
                      external
                      newPage
                      className="py-4 justify-content-sm-start justify-content-center"
                      data={{
                        url: domNode.children.find((o) => o.name === 'a')
                          .attribs.href,
                        title: domNode.children.find((o) => o.name === 'a')
                          .children[0],
                      }}
                      proto
                    />
                  )
                }

                if (
                  domNode.children
                    .find((o) => o.name === 'a')
                    .attribs.href.indexOf('contact')
                ) {
                  return (
                    <>
                      <CustomButtonWrapper
                        className={`d-flex pb-5 pt-3 ${className ? `${className}` : ``
                          }`}
                      >
                        <StyledCustomLink onClick={() => setModalOpen(true)}>
                          <Paragraph className="float-left py-2 px-4 d-flex justify-content-center align-items-center color-background-striking color-text-light text-uppercase font-weight-xl font-size-m">
                            {
                              domNode.children.find((o) => o.name === 'a')
                                .children[0].data
                            }
                          </Paragraph>
                        </StyledCustomLink>
                      </CustomButtonWrapper>
                      <ContactModal
                        service={data.title}
                        id={1}
                        type={data.title}
                        title="Contact opnemen"
                        modalIsOpen={modalIsOpen}
                        setModalOpen={setModalOpen}
                      />
                    </>
                  )
                }
                return (
                  <CustomButton
                    className="py-4 justify-content-sm-start justify-content-center"
                    data={{
                      url: domNode.children.find((o) => o.name === 'a').attribs
                        .href,
                      title: domNode.children.find((o) => o.name === 'a')
                        .children[0],
                    }}
                    proto
                  />
                )
              }
            } else if (domNode.name === 'h2') {
              return (
                <Title
                  proto
                  luxe
                  data={{
                    title: domNode.children[0].data.split('/')[0],
                    sub_text: domNode.children[0].data.split('/')[1],
                  }}
                />
              )
            } else if (domNode.name === 'h1') {
              return (
                <>
                  <Title
                    className="text-lg-left text-center pb-3"
                    proto
                    data={{ title: domNode.children[0].data }}
                  />
                  <div className="pb-3 d-lg-none d-flex justify-content-center justify-content-lg-start">
                    <StyledButtonDefault
                      isAnchor
                      to={`tel:${data.phonenumber}`}
                    >
                      <div className="d-flex align-items-center px-2">
                        <img className="mr-2" src={whitePhone} alt="" />
                        <p className="mb-0 font-size-xm">
                          Bel voor een afspraak
                        </p>
                      </div>
                    </StyledButtonDefault>
                  </div>
                  <div className="pb-5 d-lg-none d-flex justify-content-center justify-content-lg-start">
                    <StyledButtonDefault
                      green
                      isAnchor
                      to={`whatsapp:${data.whatsapp}`}
                    >
                      <div className="d-flex align-items-center px-2">
                        <img className="mr-2" src={whatsappWhite} alt="" />
                        <p className="mb-0 font-size-xm">
                          App voor een afspraak
                        </p>
                      </div>
                    </StyledButtonDefault>
                  </div>
                </>
              )
            } else if (domNode.name === 'ul') {
              return (
                <SellingPoints
                  proto
                  data={domNode.children}
                  className="pl-lg-3 pb-2 pt-1"
                />
              )
            } else if (domNode.name === 'blockquote') {
              return <ProtoQuote data={domNode.children[0].children} />
            } else if (domNode.name === 'a') {
              let returnElement

              if (
                domNode.attribs.href.indexOf('http://') === 0 ||
                domNode.attribs.href.indexOf('https://') === 0
              ) {
                returnElement = (
                  <LinkCustomLink
                    className="font-size-sm"
                    newPage
                    external
                    to={domNode.attribs.href}
                  >
                    <Paragraph className="font-size-sm color-text-striking">
                      {domNode.children[0].data}
                    </Paragraph>
                  </LinkCustomLink>
                )
              } else {
                returnElement = (
                  <LinkCustomLink
                    className="font-size-sm"
                    to={domNode.attribs.href}
                  >
                    <Paragraph className="font-size-sm color-text-striking">
                      {domNode.children[0].data}
                    </Paragraph>
                  </LinkCustomLink>
                )
              }

              return returnElement
            }
            return true
          },
        })}
      </InnerContentContainer>
    </ContentWrapper>
  )
}

export default WaardeContent

const QuoteWrapper = styled.div``

const QuoteContainer = styled.div`
  img {
    height: 16px;
    width: 20px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    line-height: 1.32;
    letter-spacing: -0.24px;
    width: 80%;
  }

  @media (max-width: 991px) {
    p {
      width: 100%;
    }
  }

  ::before {
    content: '"';
    position: absolute;
    font-size: 60px;
    font-weight: ${(props) => props.theme.font.weight.xl};
    color: ${(props) => props.theme.color.text.striking};
  }
`

const ProtoQuote = ({ data, className }) => (
  <QuoteWrapper className={`px-5 py-4 ${className ? `${className}` : ``}`}>
    <QuoteContainer>
      <Paragraph className="pt-2">
        {data.map((part) => part.data && parse(part.data))}
      </Paragraph>
    </QuoteContainer>
  </QuoteWrapper>
)
